<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      accounts: [],
      basic_Salary_title: "العلاوة",
      basic_Salary_title_en: "Allawance",
      salary_items: [],
      kassalaItems: [],
      kassalaItem: {
        isNewInKassala: 0,
      },
      // search
      selectedAccounts: null,
      levelThreeAccountId: null,
      levelThreeAdAccountId: null,
      // levelThreeAccounts: [],
      levelThreeAdAccounts: [],
      girbaItems: [],
      halfaItems: [],
      branchItems: [],
      levelThreeAccounts: [],
      salaryItemsData: [],
      SalaryItems: {},
      total_degrees: 17,
      salaryItemsDegrees: [],
      salaryItemDegreesHead: [],
      page: 1,
      limit: 20,
      isNewInGirba: 0,
      isNewInHalfa: 0,
      tot_pages: 0,
      searchSalaryItems: null,
      SearchMode: false,
      selectedInput: null,
      isClicked: false,
      itemId: null,
      selectedItem: null,
      salaryItemDegreeId: null,
      salaryItemsTypes: [],
      branch_id: null,
      // appprices:[]
    };
  },
  methods: {

    searchAccounts(account) {
      console.log(account);
      this.levelThreeAccountId = account?.search?.split("-")[0];
      console.log(this.levelThreeAccountId);
      this.levelTwoAccounts = [];
      this.http
        .post("level-three-chart-of-accounts-copy-1/accounts-setup/salary-item-branches", {
          leveThreeId: this.levelThreeAccountId,
          id: account.id,
        })
        .then((res) => {
          if (res.status) {
            console.log("done");
            account.search = "";
            this.getBranchesItems();
          }
        });
    },
    selectedAccount(account) {
      this.http
        .post("salary-items/types/setup/accounts/search", {
          search: account.search,
          type: "account",
        })
        .then((res) => {
          if (res.status) {
            this.levelThreeAccounts = res.data.map((a) => {
              return this.$i18n.locale == "ar"
                ? a.code + "-" + a.name
                : a.code + "-" + a.name_en;
            });
          }
        });
    },
    preEditSalaryItem(salary) {
      var data = {
        title: "popups.editsalaryItem",
        inputs: [
          {
            model: "popups.name_ar",
            type: "text",
            label: "popups.name",
            value: salary.name,
          },
          {
            model: "name_en",
            type: "text",
            label: "popups.name_en",
            value: salary.name_en,
          },

          {
            model: "start_degree",
            type: "select",
            label: "salaryItems.start_degree",
            value:salary.start_degree,
            options: [
              { value: 1, label: 1, checked: true },
              { value: 2, label: 2 },
              { value: 3, label: 3 },
              { value: 4, label: 4 },
              { value: 5, label: 5 },
              { value: 6, label: 6 },
              { value: 7, label: 7 },
              { value: 8, label: 8 },
              { value: 9, label: 9 },
              { value: 10, label: 10 },
              { value: 11, label: 11 },
              { value: 12, label: 12 },
              { value: 13, label: 13 },
              { value: 14, label: 14 },
              { value: 15, label: 15 },
              { value: 16, label: 16 },
              { value: 17, label: 17 },
            ],
          },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.put("salary-items", salary.id, obj).then(() => {
                this.get(this.page);
                this.getSalaryItems()
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    addSalaryItems() {
      var data = {
        title: "popups.addsalaryitem",
        inputs: [
          { model: "name", type: "text", label: "popups.name_ar" },
          { model: "name_en", type: "text", label: "popups.name_en" },
          {
            model: "start_degree",
            type: "select",
            label: "salaryItems.start_degree",
            options: [
              { value: 1, label: 1, checked: true },
              { value: 2, label: 2 },
              { value: 3, label: 3 },
              { value: 4, label: 4 },
              { value: 5, label: 5 },
              { value: 6, label: 6 },
              { value: 7, label: 7 },
              { value: 8, label: 8 },
              { value: 9, label: 9 },
              { value: 10, label: 10 },
              { value: 11, label: 11 },
              { value: 12, label: 12 },
              { value: 13, label: 13 },
              { value: 14, label: 14 },
              { value: 15, label: 15 },
              { value: 16, label: 16 },
              { value: 17, label: 17 },
            ],
          },
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.post("salary-items", obj).then(() => {
                this.get(this.page);
                this.getSalaryItems()
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    search() {
      this.SearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("salary-items/search", {
          search: this.searchSalaryItems,
          limit: this.limit,
          page: this.page,
          col: "name",
        })
        .then((res) => {
          this.salary_items = res.data;
        });
    },
    addSalaryItemBranch(branch_id) {
      console.log(branch_id);
      let locale = this.$i18n.locale;
      this.http
        .post("salary-items-branches/get-all-items-except", { branch_id })
        .then((resp) => {
          this.salaryItemsData = resp.data;
          var data = {
            title: "popups.addsalaryitem",
            inputs: [
              {
                model: "salary_item_id",
                type: "select",
                options: (() => {
                  return this.salaryItemsData.map((data) => {
                    return {
                      value: data.id,
                      label: locale == "ar" ? data.name : data.name_en,
                    };
                  });
                })(),
                label: "salaryItems.choose_salary_item",
              },
            ],
            buttons: [
              {
                text: "popups.add",
                closer: true,
                color: "primary",
                handler: (obj) => {
                  obj.branch_id = branch_id;
                  this.http.post("salary-items-branches", obj).then(() => {
                    this.getBranchItems(branch_id);
                  });
                },
              },
              {
                text: "popups.cancel",
                closer: true,
                color: "danger",
                handler: () => {
                  console.log("Like Clicked");
                },
              },
            ],
          };
          this.popup.modal(data);
        });
    },
    getBranchItems(branch_id) {
      this.http
        .post("salary-items-branches/branch-items", { branch_id })
        .then((resp) => {
          if (branch_id == 1) {
            this.kassalaItems = resp.data;
          }
          if (branch_id == 2) {
            this.girbaItems = resp.data;
          }
          if (branch_id == 3) {
            this.halfaItems = resp.data;
          }
        });
    },
    deleteSalaryBranchItem(id, branch_id) {
      var data = {
        title: "popups.delete",
        msg: "popups.deletemsg",
      };
      this.popup.confirm(data).then((res) => {
        if (res) {
          this.http
            .post("salary-items-branches/delete", {
              salary_item_id: id,
              branch_id: branch_id,
            })
            .then(() => {
              this.getBranchItems(branch_id);
            });
        }
      });
    },
    getBranchesItems() {
      this.http
        .post("salary-items-branches/all-items", { branch_id: this.branch_id })
        .then((resp) => {
          this.kassalaItems = resp.kassalaItems;
          this.girbaItems = resp.girbaItems;
          this.halfaItems = resp.halfaItems;
        });
    },
    cancelappsearchMode() {
      this.searchSalaryItems = "";
      this.SalaryItemsSearchMode = false;
      this.get(this.page);
    },
    getSalaryItems() {
      this.http.get("salary-item-degrees/degrees").then((res) => {
        this.salaryItemsDegrees = res.data;
        this.salaryItemDegreesHead = res.salaryItemDegreesHeader;
        console.log("salaryItemsDegrees", this.salaryItemsDegrees);
        console.log("salaryItemDegreesHead", this.salaryItemDegreesHead);
      });
    },
    deleteSalaryItems(app) {
      var data = {
        title: "popups.deletesalaryItem",
        msg: "popups.deletemsg",
      };
      this.popup.confirm(data).then((res) => {
        if (res) {
          this.http.delete("salary-items", app.id).then(() => {
            this.get(this.page);
          });
        }
      });
    },
    cancelSearchMode() {
      this.searchSalaryItems = "";
      this.SearchMode = false;
      this.get(this.page);
    },
    updatedSalaryItemBasicValue(event, item, value,degree) {
     let data = {
       ["value"+value]: parseFloat(event.target.value),
       salary_item_id:item.salary_item.id,
       degree
     }
     if(item.id)
      this.http.put("salary-item-degrees", item.id, data).then((resp) => {
        if (resp.status) {
          this.getSalaryItems()
        }
      });
     else
       this.http.post("salary-item-degrees", data).then((resp) => {
         if (resp.status) {
           this.getSalaryItems()
         }
       });
    },
    updatedSalaryItemValue(event, item,degree) {
      if(item.id)
      this.http
        .put("salary-item-degrees", item.id, {
          value: parseFloat(event.target.value),
        })
        .then((resp) => {
          if (resp.status) {
            this.getSalaryItems()
          }
        });
      else{
        this.http
            .post("salary-item-degrees", {
              value: parseFloat(event.target.value),
              salary_item_id:item.salary_item.id,
              degree
            })
            .then((resp) => {
              if (resp.status) {
                this.getSalaryItems()
              }
            });
      }
    },

    get(page) {
      console.log(page);
      this.http
        .post("salary-items/pagination", {
          limit: this.limit,
          page: page,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.salary_items = res.data;
        });
    },
  },
  computed: {
    filteredKassalaAccounts() {
      return this.levelThreeAccounts.filter((account) => {
        return account;
      });
    },
    // filteredGirbaAccounts() {
    //   return this.levelThreeAccounts.filter((account) => {
    //     return account;
    //   });
    // },
    filteredAccounts() {
      return this.levelThreeAccounts.filter((account) => {
        return account;
      });
    },
    filteredHalfaAccounts() {
      return this.levelThreeAccounts.filter((account) => {
        return account;
      });
    },
  },
  created() {
    this.get(1);
    this.getSalaryItems();
    this.getBranchesItems();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :dir="$i18n.locale == 'ar' ? 'rtl' : ''" :title="$t('salaryItems.title')" />
    <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
      <b-tab active>
        <template v-slot:title>
          <span class="d-inline-block d-sm-none">
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block">{{ $t("salaryItems.salary_items") }}
          </span>
        </template>
        <div class="row">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-6">
                  <div class="search-box chat-search-box" style="width: 250px">
                    <div class="position-relative">
                      <input :dir="$i18n.locale == 'ar' ? 'rtl' : ''" @keyup="search()" type="text"
                        class="form-control fa-lg text-light" :placeholder="$t('salaryItems.search')"
                        v-model="searchSalaryItems" style="background-color: #2a3042 !important" />
                      <i class="bx bx-search-alt search-icon text-light"></i>
                      <button @click="cancelSearchMode()" v-if="SearchMode" class="btn-close text-light"
                        style="position: absolute; top: 12px; left: 250px"></button>
                    </div>
                  </div>
                </div>
                <div class="col-6" style="display: flex; justify-content: flex-end">
                  <button type="button" @click="addSalaryItems()" class="btn btn-light float-end mb-4">
                    <span class="bx bxs-plus-circle float-end fa-2x" style="color: #2a3042 !important"></span>
                  </button>
                </div>
              </div>

              <div class="table-responsive">
                <table class="table table-centered table-nowrap table-striped table-hover align-middle"
                  :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
                  <thead>
                    <tr class="text-light" style="background-color: #2a3042 !important">
                      <th scope="col">#</th>
                      <th scope="col">{{ $t("salaryItems.table.name") }}</th>
                      <th scope="col">
                        {{ $t("salaryItems.table.startdegree") }}
                      </th>
                      <th scope="col">{{ $t("salaryItems.table.created") }}</th>
                      <th scope="col">{{ $t("salaryItems.table.updated") }}</th>
                      <th scope="col">
                        {{ $t("salaryItems.table.operations") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="" v-for="(salary, index) in salary_items" :key="salary">
                      <td>{{ index + 1 }}</td>
                      <td>
                        {{
                          $i18n.locale == "ar" ? salary?.name : salary?.name_en
                        }}
                      </td>
                      <td>{{ salary.start_degree }}</td>
                      <td>{{ salary.created.split("T")[0] }}</td>
                      <td>{{ salary.updated.split("T")[0] }}</td>
                      <td>
                        <button type="button" class="btn btn-primary" style="margin-inline-end: 4px"
                          data-bs-target="#editModal" data-bs-toggle="modal" @click="preEditSalaryItem(salary)">
                          {{ $t("popups.edit") }}
                        </button>

                        <button class="btn btn-danger" href="javascript:void(0)" role="button"
                          @click="deleteSalaryItems(salary)">
                          {{ $t("popups.delete") }}
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- end  table -->

        <!--   Apps  Pagination     -->
        <ul class="pagination pagination-rounded justify-content-center mb-2">
          <li class="page-item" :class="{ disabled: page == 1 }">
            <a class="page-link" href="javascript: void(0);" @click="get(page - 1)" aria-label="Previous">
              <i class="mdi mdi-chevron-left"></i>
            </a>
          </li>
          <li class="page-item" :class="{ active: p == page }" v-for="p in tot_pages" :key="p">
            <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
              p
            }}</a>
          </li>

          <li class="page-item" :class="{ disabled: page == tot_pages }">
            <a class="page-link" href="javascript: void(0);" @click="get(page + 1)" aria-label="Next">
              <i class="mdi mdi-chevron-right"></i>
            </a>
          </li>
        </ul>
      </b-tab>
      <!-- start salary items degrees tab -->
      <b-tab>
        <template v-slot:title>
          <span class="d-inline-block d-sm-none">
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block">{{ $t("salaryItems.salary_items_degrees") }}
          </span>
        </template>

        <div class="row">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table id="degrees" class="table table-centered table-nowrap table-striped table-hover align-middle"
                  :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
                  <thead>
                    <tr class="text-light" style="background-color: #2a3042 !important">
                      <th scope="col">{{ $t("salaryItems.degrees") }}</th>
                      <th scope="col" v-for="item in salaryItemDegreesHead" :key="item">
                        <span>{{
                          $i18n.locale == "ar" ? item?.name : item?.name_en
                        }}</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(salaryItemDegree, index) in salaryItemsDegrees" :key="salaryItemDegree">
                      <td>{{ index + 1 }}</td>
                      <!-- <td> -->
                      <!-- <tr> -->
                      <td v-for="item in salaryItemDegree" :key="item">
                        <!-- <div class="d-flex"> -->
                        <!-- <span style="margin-end: 1px">{{
                        item?.salary_item?.name
                      }}</span> -->
                        <div v-if=" item && !item?.salary_item?.has_allawances">

                          <input data-toggle="tooltip" data-placement="top" :title="$i18n.locale == 'ar'
                            ? item?.salary_item?.name
                            : item?.salary_item?.name_en
                            " type="number" @change="updatedSalaryItemValue($event, item,index+1)" :value="item?.value" />
                        </div>
                        <div class="d-flex" v-if="item?.salary_item?.has_allawances">
                          <input class="mx-2" data-toggle="tooltip" data-placement="top" :title="$i18n.locale == 'ar'
                            ? item?.salary_item?.name
                            : item?.salary_item?.name_en
                            " type="number" @change="updatedSalaryItemValue($event, item,index+1)" :value="item?.value" />
                          <input class="mx-2" data-toggle="tooltip" data-placement="top" :title="$i18n.locale == 'ar'
                            ? item?.salary_item?.name +
                            ` ${basic_Salary_title} 1`
                            : item?.salary_item?.name_en +
                            ` ${basic_Salary_title_en} 1`
                            " type="number" @change="
    updatedSalaryItemBasicValue($event, item, 1,index+1)
    " :value="item?.value1" />
                          <input class="mx-2" data-toggle="tooltip" data-placement="top" :title="$i18n.locale == 'ar'
                            ? item?.salary_item?.name +
                            ` ${basic_Salary_title} 2`
                            : item?.salary_item?.name_en +
                            ` ${basic_Salary_title_en} 2`
                            " type="number" @change="
    updatedSalaryItemBasicValue($event, item, 2,index+1)
    " :value="item?.value2" />
                          <input class="mx-2" data-toggle="tooltip" data-placement="top" :title="$i18n.locale == 'ar'
                            ? item?.salary_item?.name +
                            ` ${basic_Salary_title} 3`
                            : item?.salary_item?.name_en +
                            ` ${basic_Salary_title_en} 3`
                            " type="number" @change="
    updatedSalaryItemBasicValue($event, item, 3,index+1)
    " :value="item?.value3" />
                          <input class="mx-2" data-toggle="tooltip" data-placement="top" :title="$i18n.locale == 'ar'
                            ? item?.salary_item?.name +
                            ` ${basic_Salary_title} 4`
                            : item?.salary_item?.name_en +
                            ` ${basic_Salary_title_en} 4`
                            " type="number" @change="
    updatedSalaryItemBasicValue($event, item, 4,index+1)
    " :value="item?.value4" />
                          <input class="mx-2" data-toggle="tooltip" data-placement="top" :title="$i18n.locale == 'ar'
                            ? item?.salary_item?.name +
                            ` ${basic_Salary_title} 5`
                            : item?.salary_item?.name_en +
                            ` ${basic_Salary_title_en} 5`
                            " type="number" @change="
    updatedSalaryItemBasicValue($event, item, 5,index+1)
    " :value="item?.value5" />
                          <input class="mx-2" data-toggle="tooltip" data-placement="top" :title="$i18n.locale == 'ar'
                            ? item?.salary_item?.name +
                            ` ${basic_Salary_title} 6`
                            : item?.salary_item?.name_en +
                            ` ${basic_Salary_title_en} 6`
                            " type="number" @change="
    updatedSalaryItemBasicValue($event, item, 6,index+1)
    " :value="item?.value6" />
                        </div>
                        <!-- </div> -->
                        <!-- </td> -->
                        <!-- </tr> -->
                      </td>
                    </tr>
                    <!-- <td v-for="i in 17" :key="i">{{ i }}</td> -->
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- end  table -->
      </b-tab>
      <!-- end salary items degrees tab -->


      <!-- start girba items tab -->
      <b-tab>
        <template v-slot:title>
          <span class="d-inline-block d-sm-none">
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block">{{ $t("accounts_setup.setup") }}
          </span>
        </template>
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-6">
                    <div class="search-box chat-search-box" style="width: 250px">
                      <div class="position-relative">
                        <input :dir="$i18n.locale == 'ar' ? 'rtl' : ''" @keyup="search()" type="text"
                          class="form-control fa-lg text-light" :placeholder="$t('salaryItems.search')"
                          v-model="searchSalaryItems" style="background-color: #2a3042 !important" />
                        <i class="bx bx-search-alt search-icon text-light"></i>
                        <button @click="cancelSearchMode()" v-if="SearchMode" class="btn-close text-light"
                          style="position: absolute; top: 12px; left: 250px"></button>
                      </div>
                    </div>
                  </div>
                  <div class="col-6" style="display: flex; justify-content: flex-end">
                    <button @click="addSalaryItemBranch(2)" type="button" class="btn btn-light float-end mb-4">
                      <span class="bx bxs-plus-circle float-end fa-2x" style="color: #2a3042 !important"></span>
                    </button>
                  </div>
                </div>
                <div class="table-responsive">
                  <table class="table table-centered table-nowrap table-striped table-hover align-middle"
                    :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
                    <thead>
                      <tr class="text-light" style="background-color: #2a3042 !important">
                        <th scope="col">#</th>
                        <th scope="col">{{ $t("salaryItems.table.name") }}</th>
                        <th scope="col">
                          {{ $t("accounts_setup.selected_account_id") }}
                        </th>
                        <th scope="col">
                          {{ $t("fixed_assets.account_name") }}
                        </th>
                        <th scope="col">
                          {{ $t("salaryItems.table.operations") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="" v-for="(item, index) in girbaItems" :key="item">
                        <td>{{ index + 1 }}</td>
                        <td>
                          {{
                            $i18n.locale == "ar"
                            ? item?.salary_item?.name
                            : item?.salary_item?.name_en
                          }}
                        </td>
                        <td>{{ item?.level_three_chart_of_account?.code }}</td>
                        <td>
                          {{
                            $i18n.locale == "ar"
                            ? item?.level_three_chart_of_account?.name
                            : item?.level_three_chart_of_account?.name_en
                          }}
                        </td>
                        <td>
                          <div class="d-flex">
                            <AutoComplete @keyup="selectedAccount(item)" v-model="item.search"
                              :suggestions="filteredAccounts" @complete="item.search = $event.query" style="
                              color: #333 !important;
                              border-radius: 10px !important;
                            " />
                            <button style="margin-inline-start: 8px" @click="searchAccounts(item)" type="button"
                              class="btn btn-primary">
                              {{ $t("popups.edit") }}
                            </button>
                          </div>
                        </td>
                        <td>
                          <button class="btn btn-danger btn-sm" role="button"
                            @click="deleteSalaryBranchItem(item?.id, 2)">
                            {{ $t("popups.delete") }}
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-tab>
      <!-- end girba items tab -->


    </b-tabs>
    <!--end-->
  </Layout>
</template>
<style scoped>
#inputValue {
  border: 0;
  background: transparent;
}
</style>
